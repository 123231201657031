function _inheritsLoose(subClass, superClass) { subClass.prototype = Object.create(superClass.prototype); subClass.prototype.constructor = subClass; subClass.__proto__ = superClass; }

import Span from './span';
import SpanBase from './span-base';
import { generateRandomId, merge, now, getTime, extend, removeInvalidChars } from '../common/utils';
import { REUSABILITY_THRESHOLD, TRUNCATED_TYPE } from '../common/constants';
import { captureBreakdown as _captureBreakdown } from './breakdown';

var Transaction = function (_SpanBase) {
  _inheritsLoose(Transaction, _SpanBase);

  function Transaction(name, type, options) {
    var _this;

    _this = _SpanBase.call(this, name, type, options) || this;
    _this.traceId = generateRandomId();
    _this.marks = undefined;
    _this.spans = [];
    _this._activeSpans = {};
    _this.nextAutoTaskId = 1;
    _this._scheduledTasks = [];
    _this.captureTimings = false;
    _this.breakdownTimings = [];
    _this.sampled = Math.random() <= _this.options.transactionSampleRate;
    return _this;
  }

  var _proto = Transaction.prototype;

  _proto.addMarks = function addMarks(obj) {
    this.marks = merge(this.marks || {}, obj);
  };

  _proto.mark = function mark(key) {
    var skey = removeInvalidChars(key);

    var markTime = now() - this._start;

    var custom = {};
    custom[skey] = markTime;
    this.addMarks({
      custom: custom
    });
  };

  _proto.canReuse = function canReuse() {
    var threshold = this.options.reuseThreshold || REUSABILITY_THRESHOLD;
    return !!this.options.canReuse && !this.ended && now() - this._start < threshold;
  };

  _proto.redefine = function redefine(name, type, options) {
    if (name) {
      this.name = name;
    }

    if (type) {
      this.type = type;
    }

    if (options) {
      extend(this.options, options);
    }
  };

  _proto.startSpan = function startSpan(name, type, options) {
    var _this2 = this;

    if (this.ended) {
      return;
    }

    var opts = extend({}, options);

    opts.onEnd = function (trc) {
      _this2._onSpanEnd(trc);
    };

    opts.traceId = this.traceId;
    opts.sampled = this.sampled;

    if (!opts.parentId) {
      opts.parentId = this.id;
    }

    var span = new Span(name, type, opts);
    this._activeSpans[span.id] = span;
    return span;
  };

  _proto.isFinished = function isFinished() {
    return this._scheduledTasks.length === 0;
  };

  _proto.detectFinish = function detectFinish() {
    if (this.isFinished()) this.end();
  };

  _proto.end = function end(endTime) {
    if (this.ended) {
      return;
    }

    this.ended = true;
    this._end = getTime(endTime);

    for (var sid in this._activeSpans) {
      var span = this._activeSpans[sid];
      span.type = span.type + TRUNCATED_TYPE;
      span.end(endTime);
    }

    this.callOnEnd();
  };

  _proto.captureBreakdown = function captureBreakdown() {
    this.breakdownTimings = _captureBreakdown(this);
  };

  _proto.addTask = function addTask(taskId) {
    if (typeof taskId === 'undefined') {
      taskId = 'task' + this.nextAutoTaskId++;
    }

    if (this._scheduledTasks.indexOf(taskId) == -1) {
      this._scheduledTasks.push(taskId);

      return taskId;
    }
  };

  _proto.removeTask = function removeTask(taskId) {
    var index = this._scheduledTasks.indexOf(taskId);

    if (index > -1) {
      this._scheduledTasks.splice(index, 1);
    }

    this.detectFinish();
  };

  _proto.resetSpans = function resetSpans() {
    this.spans = [];
  };

  _proto._onSpanEnd = function _onSpanEnd(span) {
    this.spans.push(span);
    delete this._activeSpans[span.id];
  };

  _proto.isManaged = function isManaged() {
    return !!this.options.managed;
  };

  return Transaction;
}(SpanBase);

export default Transaction;