import { Promise } from '../common/polyfills';
import Transaction from './transaction';
import { PerfEntryRecorder, captureObserverEntries, metrics, createTotalBlockingTimeSpan } from './metrics';
import { extend, getEarliestSpan, getLatestNonXHRSpan } from '../common/utils';
import { captureNavigation } from './capture-navigation';
import { PAGE_LOAD, NAME_UNKNOWN, TRANSACTION_START, TRANSACTION_END, TEMPORARY_TYPE, TRANSACTION_TYPE_ORDER, LARGEST_CONTENTFUL_PAINT, LONG_TASK, PAINT, TRUNCATED_TYPE, FIRST_INPUT } from '../common/constants';
import { addTransactionContext } from '../common/context';
import { __DEV__, state } from '../state';
import { slugifyUrl } from '../common/url';

var TransactionService = function () {
  function TransactionService(logger, config) {
    var _this = this;

    this._config = config;
    this._logger = logger;
    this.currentTransaction = undefined;
    this.respIntervalId = undefined;
    this.recorder = new PerfEntryRecorder(function (list) {
      var tr = _this.getCurrentTransaction();

      if (tr && tr.captureTimings) {
        var _tr$spans;

        var capturePaint = false;

        if (tr.type === PAGE_LOAD) {
          capturePaint = true;
        }

        var _captureObserverEntri = captureObserverEntries(list, {
          capturePaint: capturePaint
        }),
            spans = _captureObserverEntri.spans,
            marks = _captureObserverEntri.marks;

        (_tr$spans = tr.spans).push.apply(_tr$spans, spans);

        tr.addMarks({
          agent: marks
        });
      }
    });
  }

  var _proto = TransactionService.prototype;

  _proto.ensureCurrentTransaction = function ensureCurrentTransaction(name, type, options) {
    var tr = this.getCurrentTransaction();

    if (tr) {
      return tr;
    } else {
      tr = new Transaction(name, type, options);
      this.setCurrentTransaction(tr);
    }

    return tr;
  };

  _proto.getCurrentTransaction = function getCurrentTransaction() {
    if (this.currentTransaction && !this.currentTransaction.ended) {
      return this.currentTransaction;
    }
  };

  _proto.setCurrentTransaction = function setCurrentTransaction(value) {
    this.currentTransaction = value;
  };

  _proto.createOptions = function createOptions(options) {
    var config = this._config.config;
    var presetOptions = {
      transactionSampleRate: config.transactionSampleRate
    };
    var perfOptions = extend(presetOptions, options);

    if (perfOptions.managed) {
      perfOptions = extend({
        pageLoadTraceId: config.pageLoadTraceId,
        pageLoadSampled: config.pageLoadSampled,
        pageLoadSpanId: config.pageLoadSpanId,
        pageLoadTransactionName: config.pageLoadTransactionName
      }, perfOptions);
    }

    return perfOptions;
  };

  _proto.startManagedTransaction = function startManagedTransaction(name, type, perfOptions) {
    var tr = this.getCurrentTransaction();
    var isRedefined = false;

    if (!tr) {
      tr = this.ensureCurrentTransaction(name, type, perfOptions);
    } else if (tr.canReuse() && perfOptions.canReuse) {
      var redefineType = tr.type;
      var currentTypeOrder = TRANSACTION_TYPE_ORDER.indexOf(tr.type);
      var redefineTypeOrder = TRANSACTION_TYPE_ORDER.indexOf(type);

      if (currentTypeOrder >= 0 && redefineTypeOrder < currentTypeOrder) {
        redefineType = type;
      }

      if (__DEV__) {
        this._logger.debug("redefining transaction(" + tr.id + ", " + tr.name + ", " + tr.type + ")", 'to', "(" + (name || tr.name) + ", " + redefineType + ")", tr);
      }

      tr.redefine(name, redefineType, perfOptions);
      isRedefined = true;
    } else {
      if (__DEV__) {
        this._logger.debug("ending previous transaction(" + tr.id + ", " + tr.name + ")", tr);
      }

      tr.end();
      tr = this.ensureCurrentTransaction(name, type, perfOptions);
    }

    if (tr.type === PAGE_LOAD) {
      if (!isRedefined) {
        this.recorder.start(LARGEST_CONTENTFUL_PAINT);
        this.recorder.start(PAINT);
        this.recorder.start(FIRST_INPUT);
      }

      if (perfOptions.pageLoadTraceId) {
        tr.traceId = perfOptions.pageLoadTraceId;
      }

      if (perfOptions.pageLoadSampled) {
        tr.sampled = perfOptions.pageLoadSampled;
      }

      if (tr.name === NAME_UNKNOWN && perfOptions.pageLoadTransactionName) {
        tr.name = perfOptions.pageLoadTransactionName;
      }
    }

    if (!isRedefined && this._config.get('monitorLongtasks')) {
      this.recorder.start(LONG_TASK);
    }

    if (tr.sampled) {
      tr.captureTimings = true;
    }

    return tr;
  };

  _proto.startTransaction = function startTransaction(name, type, options) {
    var _this2 = this;

    var perfOptions = this.createOptions(options);
    var tr;
    var fireOnstartHook = true;

    if (perfOptions.managed) {
      var current = this.currentTransaction;
      tr = this.startManagedTransaction(name, type, perfOptions);

      if (current === tr) {
        fireOnstartHook = false;
      }
    } else {
      tr = new Transaction(name, type, perfOptions);
    }

    tr.onEnd = function () {
      return _this2.handleTransactionEnd(tr);
    };

    if (fireOnstartHook) {
      if (__DEV__) {
        this._logger.debug("startTransaction(" + tr.id + ", " + tr.name + ", " + tr.type + ")");
      }

      this._config.events.send(TRANSACTION_START, [tr]);
    }

    return tr;
  };

  _proto.handleTransactionEnd = function handleTransactionEnd(tr) {
    var _this3 = this;

    this.recorder.stop();
    var currentUrl = window.location.href;
    return Promise.resolve().then(function () {
      var name = tr.name,
          type = tr.type;
      var lastHiddenStart = state.lastHiddenStart;

      if (lastHiddenStart >= tr._start) {
        if (__DEV__) {
          _this3._logger.debug("transaction(" + tr.id + ", " + name + ", " + type + ") was discarded! The page was hidden during the transaction!");
        }

        return;
      }

      if (_this3.shouldIgnoreTransaction(name) || type === TEMPORARY_TYPE) {
        if (__DEV__) {
          _this3._logger.debug("transaction(" + tr.id + ", " + name + ", " + type + ") is ignored");
        }

        return;
      }

      if (type === PAGE_LOAD) {
        var pageLoadTransactionName = _this3._config.get('pageLoadTransactionName');

        if (name === NAME_UNKNOWN && pageLoadTransactionName) {
          tr.name = pageLoadTransactionName;
        }

        if (tr.captureTimings && metrics.tbt.duration > 0) {
          tr.spans.push(createTotalBlockingTimeSpan(metrics.tbt));
        }
      }

      if (tr.name === NAME_UNKNOWN) {
        tr.name = slugifyUrl(currentUrl);
      }

      captureNavigation(tr);

      _this3.adjustTransactionTime(tr);

      var breakdownMetrics = _this3._config.get('breakdownMetrics');

      if (breakdownMetrics) {
        tr.captureBreakdown();
      }

      var configContext = _this3._config.get('context');

      addTransactionContext(tr, configContext);

      _this3._config.events.send(TRANSACTION_END, [tr]);

      if (__DEV__) {
        _this3._logger.debug("end transaction(" + tr.id + ", " + tr.name + ")", tr);
      }
    }, function (err) {
      if (__DEV__) {
        _this3._logger.debug("error ending transaction(" + tr.id + ", " + tr.name + ")", err);
      }
    });
  };

  _proto.adjustTransactionTime = function adjustTransactionTime(transaction) {
    var spans = transaction.spans;
    var earliestSpan = getEarliestSpan(spans);

    if (earliestSpan && earliestSpan._start < transaction._start) {
      transaction._start = earliestSpan._start;
    }

    var latestSpan = getLatestNonXHRSpan(spans);

    if (latestSpan && latestSpan._end > transaction._end) {
      transaction._end = latestSpan._end;
    }

    var transactionEnd = transaction._end;

    for (var i = 0; i < spans.length; i++) {
      var span = spans[i];

      if (span._end > transactionEnd) {
        span._end = transactionEnd;
        span.type += TRUNCATED_TYPE;
      }

      if (span._start > transactionEnd) {
        span._start = transactionEnd;
      }
    }
  };

  _proto.shouldIgnoreTransaction = function shouldIgnoreTransaction(transactionName) {
    var ignoreList = this._config.get('ignoreTransactions');

    if (ignoreList && ignoreList.length) {
      for (var i = 0; i < ignoreList.length; i++) {
        var element = ignoreList[i];

        if (typeof element.test === 'function') {
          if (element.test(transactionName)) {
            return true;
          }
        } else if (element === transactionName) {
          return true;
        }
      }
    }

    return false;
  };

  _proto.startSpan = function startSpan(name, type, options) {
    var tr = this.ensureCurrentTransaction(undefined, TEMPORARY_TYPE, this.createOptions({
      canReuse: true,
      managed: true
    }));

    if (tr) {
      var span = tr.startSpan(name, type, options);

      if (__DEV__) {
        this._logger.debug("startSpan(" + name + ", " + type + ")", "on transaction(" + tr.id + ", " + tr.name + ")");
      }

      return span;
    }
  };

  _proto.addTask = function addTask(taskId) {
    var tr = this.ensureCurrentTransaction(undefined, TEMPORARY_TYPE, this.createOptions({
      canReuse: true,
      managed: true
    }));

    if (tr) {
      var taskId = tr.addTask(taskId);

      if (__DEV__) {
        this._logger.debug("addTask(" + taskId + ")", "on transaction(" + tr.id + ", " + tr.name + ")");
      }
    }

    return taskId;
  };

  _proto.removeTask = function removeTask(taskId) {
    var tr = this.getCurrentTransaction();

    if (tr) {
      tr.removeTask(taskId);

      if (__DEV__) {
        this._logger.debug("removeTask(" + taskId + ")", "on transaction(" + tr.id + ", " + tr.name + ")");
      }
    }
  };

  return TransactionService;
}();

export default TransactionService;